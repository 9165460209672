import AllGamesIcon from "@/inline-assets/all-games.svg";
import FriendsIcon from "@/inline-assets/friends.svg";
import LanguageIcon from "@/inline-assets/language.svg";
import BattlesIcon from "@/inline-assets/nav-battles.svg";

export const GENERIC_FEATURES: {
  Icon: SVGComponent;
  title: [string, string];
  desc: Translation;
}[] = [
  {
    Icon: BattlesIcon,
    title: ["welcome:genFeature.battles", "Exlusive Battles"],
    desc: [
      "welcome:genFeature.battlesDesc",
      "Daily tournaments with big prize pools!",
    ],
  },
  {
    Icon: FriendsIcon,
    title: ["welcome:genFeature.friends", "Multiple Profiles"],
    desc: [
      "welcome:genFeature.friendsDesc",
      "Connect as many profiles as you want.",
    ],
  },
  {
    Icon: null,
    title: ["welcome:genFeature.compliant", "Fully Compliant"],
    desc: [
      "welcome:genFeature.compliantDesc",
      "We work with {{publisher}} to ensure you’re safe.",
    ],
  },
  {
    Icon: AllGamesIcon,
    title: ["welcome:genFeature.all", "Growing Game Library"],
    desc: [
      "welcome:genFeature.allDesc",
      "Blitz is supporting more games each month.",
    ],
  },
  {
    Icon: LanguageIcon,
    title: ["welcome:genFeature.lang", "Multilingual"],
    desc: [
      "welcome:genFeature.langDesc",
      "We support more than 15 languages around the world.",
    ],
  },
];
