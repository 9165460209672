import { css } from "goober";

export const pageCss = () => css`
  --content-m-w: 1200px;
  --px: var(--sp-8);
  container: welcome-container / inline-size;

  position: relative;
  width: 100%;
  background-color: var(--shade10);

  .container {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: var(--sp-14);

    font-family: Inter;
    text-align: left;
    font-variation-settings: "slnt" 0;

    h1 {
      color: var(--shade0);
      font-size: var(--sp-10);
      line-height: var(--sp-12);
      font-weight: 650;
      letter-spacing: -0.015em;
    }

    h2 {
      font-size: var(--sp-8);
      line-height: var(--sp-10);
      font-weight: 625;
      letter-spacing: -0.009em;
    }

    h3 {
      font-size: var(--sp-5);
      line-height: var(--sp-8);
      font-weight: 725;
      letter-spacing: -0.009em;
    }

    .subheader {
      color: var(--shade0-75);

      strong {
        color: var(--shade0);
        font-weight: 725;
      }
    }

    nav {
      z-index: 100;
      margin-bottom: var(--sp-4);
      position: sticky;
      top: 0;
      padding: 0 var(--px);
      width: 100%;

      display: flex;
      justify-content: center;

      transition: background-color var(--transition-long);

      ul {
        max-width: var(--content-m-w);
        width: 100%;
        padding: var(--sp-4) 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .blitz-logo {
        width: var(--sp-6);
        height: var(--sp-6);
      }

      &.is-sticky {
        background-color: var(--shade9);

        @supports (backdrop-filter: blur(20px)) {
          backdrop-filter: blur(20px);
          background-color: var(--shade9-75);
        }
      }
    }

    header {
      & > div {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: var(--sp-4);
        padding: var(--sp-14) 0 var(--sp-8);

        .hero-text {
          grid-column: span 5;
          gap: var(--sp-6);
        }

        .hero-img {
          position: relative;
          grid-column: span 7;

          width: 100%;
          padding-top: 56.25%;
          backdrop-filter: blur(10px);

          img,
          video {
            position: absolute;
            inset: 0;
            width: 100%;
          }
        }

        @container welcome-container (inline-size < 900px) {
          display: flex;
          flex-direction: column;
          gap: var(--sp-14);

          .hero-text {
            gap: var(--sp-8);

            p {
              flex-direction: row;
            }
          }

          .hero-img {
            max-width: 900px;
            margin: auto;
          }
        }
      }
    }

    .centered {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 var(--px);
    }

    .content {
      max-width: var(--content-m-w);
      width: 100%;
    }

    .bg-img {
      z-index: -1;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 888px;

      background-image: var(--bg-url);
      background-position: center;
      background-size: cover;
    }

    .usp-section {
      border-top: 1px solid var(--shade3-25);
      border-bottom: 1px solid var(--shade3-25);

      position: relative;
      width: 100%;
      padding: var(--sp-3) 0;

      display: flex;
      justify-content: center;

      background-color: var(--shade7-15);
      mask-image: linear-gradient(
        90deg,
        rgba(217, 217, 217, 0) 0%,
        #d9d9d9 10%,
        #d9d9d9 90%,
        rgba(217, 217, 217, 0) 100%
      );

      .usps {
        max-width: var(--content-m-w);
        width: 100%;

        display: flex;
        justify-content: space-between;

        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: var(--sp-8);

          color: var(--shade0-50);
        }

        li:has(a):hover {
          color: var(--shade0);
        }

        a {
          text-decoration: underline;
        }
      }

      @container welcome-container (inline-size < 1008px) {
        mask-image: none;

        .desktop-only {
          display: none;
        }

        .usps {
          ul {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
      }
    }

    .feature-blocks {
      max-width: var(--content-m-w);
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, minmax(300px, 1fr));
      gap: var(--sp-4);

      @container welcome-container (inline-size < 1008px) {
        display: flex;
        flex-direction: column;
      }

      .feature {
        border-radius: var(--br);
        border: 1px solid var(--shade3-25);
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background: var(--bg-img);
        background-color: var(--shade8-50);
        background-size: cover;
        background-position: center;

        .display {
          width: 100%;
        }
      }

      &:before {
        content: "";
        z-index: -2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 2700px;
        height: 1240px;
        opacity: 0.15;

        background: radial-gradient(
          50% 50% at 49.94% 50%,
          #364364 0%,
          rgba(7, 9, 14, 0) 100%
        );
      }
    }

    .generic-features {
      max-width: var(--content-m-w);
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: var(--sp-2);

      & > * {
        max-width: 233px;
      }
    }

    .faqs {
      max-width: var(--content-m-w);
      width: 100%;

      ul {
        & > li:not(:last-child) {
          border-bottom: 1px solid var(--shade3-25);
        }

        li {
          padding: var(--sp-6) 0;
        }

        .premium {
          color: var(--subscriber);

          &:hover {
            color: var(--shade0);
          }
        }
      }
    }

    .footer {
      position: relative;
      color: var(--shade0-75);
      padding-bottom: var(--sp-21);

      &:before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: -100%;
        width: 100%;

        opacity: 0.15;
        background: radial-gradient(
          50% 50% at 49.94% 50%,
          #364364 0%,
          rgba(7, 9, 14, 0) 100%
        );
      }

      .q {
        font-family: Inter;
        font-size: 48px;
        font-weight: 650;
        line-height: 56px;
        letter-spacing: -0.015em;
        text-align: center;
        font-variation-settings: "slnt" 0;
      }
    }
  }
`;
